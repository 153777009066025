export const teamMembers = [
    {
      name: 'Raven',
      role: ['Founder'],
      tag: 'Stellar',
      description: 'Raven, the founder of Stellar, has transitioned away from an active role within the company.',
      image: 'https://cdn.discordapp.com/avatars/389491254368534528/a_2e7a4422bb7d1a5dd4d370f5d50a6c9f.gif?size=1024',
      github: 'https://github.com/Raven13111',
    },
    {
      name: 'Yekaterina',
      role: ['Owner', 'Developer', 'Site Developer', 'Bot Developer', 'Security'],
      tag: 'Stellar',
      description: 'As the owner of Stellar, Yekaterina (also known as Acaida) has taken over Raven’s former role. She specializes in security and website development.',
      image: 'https://discord.com/assets/a0180771ce23344c2a95.png?size=1024',
      github: 'https://github.com/None:)',
    },
    {
      name: '4syv',
      role: ['Lead Developer'],
      tag: 'Stellar',
      description: '4syv serves as the Lead Developer at Stellar, concentrating on critical components of AutoLaunch and other products.',
      image: 'https://discord.com/assets/a0180771ce23344c2a95.png?size=1024',
      github: 'https://github.com/4syw',
    },
    {
      name: 'Dyld',
      role: ['Lead Developer'],
      tag: 'Helix Development',
      description: 'Dyld serves as the Lead Developer at Helix Development, concentrating on critical components of Executors.',
      image: 'https://cdn.discordapp.com/avatars/683293740542066709/ecc21292d9b403de0bb01e8de9acd2f0.webp?size=1024',
      github: 'https://github.com/freegamerskids',
    }
  ];

  
  export const faqItems = [
    {
      question: 'Are we trusted?',
      answer: 'Yes, we have been in the industry for several years and ensuring we offer the best quality.'
    },
    {
      question: 'Why are the prices on the higher end?',
      answer: 'Our prices reflect the quality and reliability of our services, as well as the advanced features and support we provide to our users.'
    },
    {
      question: 'Is there a free trial available?',
      answer:
        'At this time, we do not offer a free trial. This is to ensure our methods remain exclusive and effective by limiting the number of users, preventing oversaturation, and maintaining the quality of our services.'
    },
    {
      question: 'Can I upgrade my plan later?',
      answer:
        'Absolutely! You can upgrade your plan at any time from your dashboard. The difference will be prorated to ensure fair pricing.'
    },
    {
      question: 'How secure is your platform?',
      answer:
        'We prioritize security by using encryption and best practices to protect your data. Our authentication system ensures only authorized users have access.'
    },
    {
      question: 'What payment methods do you accept?',
      answer:
        'We currently accept only cryptocurrency. However, our trusted resellers offer various payment options, including PayPal, credit cards, Robux, and more, to provide flexibility to our customers.'
    },
    {
      question: 'Can I cancel my subscription anytime?',
      answer:
        'Yes, you can cancel your subscription at any time through your dashboard. No further payments will be processed after cancellation.'
    },
    {
      question: 'Do you provide updates to your tools?',
      answer:
        'Yes, we continuously improve our tools with new features and performance updates. All updates are included with your subscription.'
    },
    {
      question: 'How does your reseller program work?',
      answer:
        'Our reseller program allows individuals to sell our services using various payment methods and earn commissions. If you are interested, please contact us for more details.'
    },
    {
      question: 'What happens if a product stops working?',
      answer:
        'We continuously monitor and update our methods to ensure effectiveness. If any method becomes outdated, we work promptly to replace it with a better solution.'
    },
  ];
  