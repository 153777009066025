import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { axiosInstance } from '../utils/api';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    if (password !== retypePassword) {
      setErrors({ retypePassword: 'Passwords do not match' });
      setLoading(false);
      return;
    }

    try {
      await axiosInstance.post('/register', { username, password });
      navigate('/login');
    } catch (error) {
      setErrors(error.response?.data?.errors || { api: error.response?.data?.message || 'Unknown error occurred' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black flex items-center justify-center p-6">
      <div className="max-w-lg w-full bg-gray-900 bg-opacity-90 rounded-xl shadow-2xl p-8 space-y-10 transform transition-all duration-500 hover:scale-105 hover:shadow-3xl">
        <header className="text-center">
          <h1 className="text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400 mb-4 animate-gradientText">
            Join Us
          </h1>
          <p className="text-gray-300 text-lg">
            Create an account and explore our amazing community!
          </p>
        </header>
        
        {errors.api && (
          <div className="bg-red-600 text-white p-4 rounded-lg mb-4 text-center shadow-lg animate-fadeIn">
            {errors.api}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="username" className="block text-lg font-semibold text-text">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`w-full px-4 py-3 border ${errors.username ? 'border-red-500' : 'border-gray-600'} rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300`}
              placeholder="Enter your username"
            />
            {errors.username && (
              <p className="text-red-500 text-sm mt-1">{errors.username}</p>
            )}
          </div>
          
          <div className="space-y-2">
            <label htmlFor="password" className="block text-lg font-semibold text-text">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-4 py-3 border ${errors.password ? 'border-red-500' : 'border-gray-600'} rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300`}
              placeholder="Enter your password"
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>
          
          <div className="space-y-2">
            <label htmlFor="retypePassword" className="block text-lg font-semibold text-text">
              Retype Password
            </label>
            <input
              type="password"
              id="retypePassword"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              className={`w-full px-4 py-3 border ${errors.retypePassword ? 'border-red-500' : 'border-gray-600'} rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300`}
              placeholder="Retype your password"
            />
            {errors.retypePassword && (
              <p className="text-red-500 text-sm mt-1">{errors.retypePassword}</p>
            )}
          </div>
          
          <button
            type="submit"
            className="w-full py-3 bg-gradient-to-r from-teal-500 to-blue-600 text-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-blue-600 hover:to-teal-500 focus:ring-4 focus:ring-primary focus:outline-none disabled:opacity-50"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        
        <div className="text-center mt-6">
          <p className="text-lg text-gray-400">
            Already have an account?{' '}
            <Link to="/login" className="text-teal-400 font-semibold hover:underline">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
