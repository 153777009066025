import axios from 'axios';
import { handleError } from './errorHandler';

const API_BASE_URL = 'http://localhost:5000/api';

export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Token set in request headers:', token);
    } else {
      console.log('No token to set in request headers');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchUserData = async () => {
  try {
    const response = await axiosInstance.get('/users/user');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching user data');
  }
};

export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get('/users');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching users');
  }
};

export const updateUserRole = async (userId, role) => {
  try {
    const response = await axiosInstance.put(`/users/${userId}/role`, { role });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error updating user role');
  }
};

export const removeUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/users/${userId}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error removing user');
  }
};

export const fetchTickets = async () => {
  try {
    const response = await axiosInstance.get('/ticket/tickets');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching tickets');
  }
};

export const fetchUserTickets = async (userId) => {
  try {
    const response = await axiosInstance.get('/ticket/tickets', { params: { userId } });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching tickets for user');
  }
};

export const fetchTicketDetails = async (ticketId) => {
  try {
    const response = await axiosInstance.get(`/ticket/tickets/${ticketId}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching ticket details');
  }
};

export const createTicket = async (ticketData) => {
  try {
    const response = await axiosInstance.post('/ticket/tickets', ticketData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error creating ticket');
  }
};

export const fetchChats = async (ticketId) => {
  try {
    const response = await axiosInstance.get(`/ticket/tickets/${ticketId}/chats`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching chat messages');
  }
};

export const postChat = async (ticketId, chatData) => {
  try {
    const response = await axiosInstance.post(`/ticket/tickets/${ticketId}/chats`, chatData);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error posting chat message');
  }
};

export const escalateTicket = async (ticketId) => {
  try {
    const response = await axiosInstance.post(`/ticket/tickets/${ticketId}/escalate`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error escalating ticket');
  }
};

export const deleteTicket = async (ticketId) => {
  try {
    const response = await axiosInstance.delete(`/ticket/tickets/${ticketId}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error deleting ticket');
  }
};

export const setup2FA = async () => {
  try {
    const response = await axiosInstance.post('/auth/2fa/setup');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error setting up 2FA');
  }
};

export const verify2FA = async (code) => {
  try {
    const response = await axiosInstance.post('/auth/2fa/verify', { code });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error verifying 2FA');
  }
};

export const createUser = async ({ username, password }) => {
  try {
    const response = await axiosInstance.post('/auth/register', { username, password });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error registering user');
  }
};

export const loginAsUser = async (userId, generatedCode) => {
  try {
    const response = await axiosInstance.post(`/auth/login-as/${userId}`, { generatedCode });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error logging in as user');
  }
};

export const logoutUser = async () => {
  try {
    localStorage.removeItem('auth_token');
    
    const response = await axiosInstance.post('/auth/logout');
    
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

export const fetchChatsForChannel = async (channelName) => {
  try {
    const response = await fetch(`http://localhost:5000/api/channels/${channelName}/chats`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching channel chats');
  }
};

export const postChatToChannel = async (channelName, chatData) => {
  try {
    const response = await axios.post(`/api/channels/${channelName}/chats`, chatData);
    return response.data;
  } catch (error) {
    handleError(error, 'Error posting channel chat message');
  }
};

export const fetchChatsForTicket = async (ticketId) => {
  try {
    const response = await axios.get(`/api/tickets/${ticketId}/chats`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching ticket chats');
  }
};

export const postChatToTicket = async (ticketId, chatData) => {
  try {
    const response = await axios.post(`/api/tickets/${ticketId}/chats`, chatData);
    return response.data;
  } catch (error) {
    handleError(error, 'Error posting ticket chat message');
  }
};

export const fetchProducts = async () => {
  try {
    const response = await axiosInstance.get('/products');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching products');
  }
};

export const fetchOwnedProducts = async () => {
  try {
    const response = await axiosInstance.get('/users/owns');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching owned products');
  }
};

export const generateKeys = async (productId, quantity) => {
  try {
    const response = await axiosInstance.post('/keys/generate', { productId, quantity });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error generating keys');
  }
};

export const fetchUserProducts = async (userId) => {
  try {
    const response = await axiosInstance.get(`/products/${userId}/products`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching user products');
  }
};

export const redeemKey = async (keyCode) => {
  try {
    const response = await axiosInstance.post('/keys/redeem', { keyCode });
    return response.data;
  } catch (error) {
    return handleError(error, 'Error redeeming key');
  }
};

export const fetchResellersWithProducts = async () => {
  try {
    const response = await axiosInstance.get('/admin/resellers-products');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching resellers with products');
  }
};

export const fetchGlobalProductSettings = async () => {
  try {
    const response = await axiosInstance.get('/admin/global-product-settings');
    return response.data;
  } catch (error) {
    return handleError(error, 'Error fetching global product settings');
  }
};

export const updateGlobalProductSettings = async (updatedSettings) => {
  try {
    const response = await axiosInstance.put('/admin/global-product-settings', updatedSettings);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error updating global product settings');
  }
};

export const updateProductAccessForResellers = async (resellerId, updatedAccess) => {
  try {
    const response = await axiosInstance.put(`/admin/reseller/${resellerId}/update-access`, updatedAccess);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error updating product access for reseller');
  }
};

export const resetHWID = async (productId) => {
  try {
    const response = await axiosInstance.post(`/products/${productId}/reset-hwid`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error resetting HWID');
  }
};

export const banUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/users/${userId}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Error banning user');
  }
};
export const enableMaintenance = async (page) => {
  try {
    const response = await axiosInstance.post('/maintenance/enable', { page });
    return response.data;
  } catch (error) {
    console.error('Error enabling maintenance:', error);
    throw error;
  }
};

export const disableMaintenance = async (page) => {
  try {
    const response = await axiosInstance.post('/maintenance/disable', { page });
    return response.data;
  } catch (error) {
    console.error('Error disabling maintenance:', error);
    throw error;
  }
};

export const fetchMaintenanceStatus = async () => {
  try {
    const response = await axiosInstance.get('/maintenance/status');
    return response.data;
  } catch (error) {
    console.error('Error fetching maintenance status:', error);
    throw error;
  }
};
