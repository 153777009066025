import React, { useState } from 'react';
import { teamMembers, faqItems } from '../data/info';
import { FaDiscord } from 'react-icons/fa';

const Home = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFAQs, setFilteredFAQs] = useState(faqItems);
  const [showAll, setShowAll] = useState(false);

  const toggleFAQ = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredFAQs(
      faqItems.filter(
        (item) =>
          item.question.toLowerCase().includes(query) ||
          item.answer.toLowerCase().includes(query)
      )
    );
  };

  const handleScrollToLearnMore = () => {
    const section = document.getElementById('learn-more');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const displayedFAQs = showAll ? filteredFAQs : filteredFAQs.slice(0, 2);

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <div className="relative flex flex-col items-center min-h-screen bg-gradient-to-br from-gray-900 via-black to-gray-900 p-6 space-y-16 text-white">
      {/* Hero Section */}
      <div className="flex flex-col items-center justify-center min-h-screen text-center space-y-8">
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-800 opacity-40"></div>
          <div className="absolute w-1/2 h-full top-0 left-1/4 transform -rotate-45 bg-gradient-to-r from-blue-500 to-teal-400 opacity-20 blur-lg"></div>
        </div>
        <h1 className="relative text-6xl md:text-7xl lg:text-8xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-600 drop-shadow-xl animate-fadeInFast z-10">
          Welcome to Stellar
        </h1>
        <p className="relative text-3xl md:text-4xl lg:text-5xl text-gray-300 mb-4 drop-shadow-md animate-fadeInFast delay-1s z-10">
          Elevating Roblox Automation
        </p>
        <div className="flex space-x-4 z-10">
          <button
            className="relative py-4 px-10 text-lg font-semibold text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-full shadow-lg hover:from-pink-500 hover:to-purple-500 hover:scale-105 transform transition duration-300 ease-in-out"
            onClick={handleScrollToLearnMore}
          >
            Learn More
          </button>
          <a
            href="https://discord.gg/Wnsb9zyW59"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Join Discord"
            className="relative flex items-center py-4 px-10 text-lg font-semibold text-white bg-gradient-to-r from-blue-500 to-green-500 rounded-full shadow-lg hover:from-green-500 hover:to-blue-500 hover:scale-105 transform transition duration-300 ease-in-out"
          >
            <FaDiscord className="mr-2" aria-hidden="true" /> Join Discord
          </a>
        </div>
        <div
          className="relative mt-12 cursor-pointer animate-bounce"
          onClick={handleScrollToLearnMore}
          role="button"
          aria-label="Scroll down"
        >
          <svg
            className="w-12 h-12 text-primary"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
      </div>

      {/* Who We Are Section */}
      <div
        id="learn-more"
        className="w-full max-w-7xl mx-auto mt-16 p-10 bg-gradient-to-br from-gray-800 via-black to-gray-900 rounded-3xl shadow-2xl overflow-hidden transform transition-all duration-500 hover:scale-105 hover:shadow-3xl"
      >
        <h2 className="relative text-5xl font-extrabold text-center text-primary mb-16 tracking-wider z-10">
          Meet the Stellar Team
        </h2>
        <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 z-10">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="relative p-8 bg-black bg-opacity-75 rounded-lg border border-primary shadow-lg hover:shadow-2xl transform hover:-translate-y-4 hover:scale-105 transition-all duration-500 group overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-transparent to-secondary opacity-0 group-hover:opacity-100 transition duration-500 pointer-events-none"></div>
              <div className="absolute top-4 right-4 bg-primary text-white rounded-full px-4 py-1 text-xs font-bold shadow-md">
                {member.tag}
              </div>
              <img
                src={member.image}
                alt={`Profile of ${member.name}`}
                className="w-32 h-32 rounded-full mx-auto mb-4 border-4 border-secondary group-hover:scale-110 transition duration-500"
              />
              <h3 className="text-3xl font-bold text-primary mb-2 text-center">{member.name}</h3>
              <h4 className="text-lg font-semibold text-secondary mb-4 text-center">
                {member.role.join(', ')}
              </h4>
              <p className="text-sm text-gray-300 text-center mb-4 leading-relaxed">{member.description}</p>
              <div className="flex flex-wrap justify-center space-x-2 mb-4">
                {member.role.map((role, roleIndex) => (
                  <div
                    key={roleIndex}
                    className="bg-gradient-to-br from-primary to-secondary text-gray-900 rounded-full px-3 py-1 text-xs font-bold shadow-md transform hover:scale-105 transition duration-300"
                  >
                    {role}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="w-full max-w-7xl mx-auto mt-16 p-10 bg-gradient-to-br from-gray-800 via-black to-gray-900 rounded-3xl shadow-2xl">
        <h2 className="text-5xl font-bold text-center text-primary mb-12">
          Frequently Asked Questions
        </h2>

        <div className="relative mb-8">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search FAQs..."
            className="w-full p-4 text-lg bg-gray-700 text-white rounded-lg border border-primary"
          />
        </div>

        <div className="space-y-8">
          {displayedFAQs.length > 0 ? (
            displayedFAQs.map((item, index) => (
              <div
                key={index}
                className="p-6 bg-black bg-opacity-80 rounded-lg border border-primary shadow-lg"
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="text-2xl font-semibold text-secondary">
                    {item.question}
                  </h3>
                  <svg
                    className={`w-6 h-6 text-secondary transform ${
                      expandedIndex === index ? 'rotate-180' : ''
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                <div
                  className={`mt-4 text-lg text-gray-300 overflow-hidden transition-max-height ${
                    expandedIndex === index ? 'max-h-96' : 'max-h-0'
                  }`}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-lg text-gray-400">
              No FAQs matched your search.
            </p>
          )}
        </div>

        {filteredFAQs.length > 2 && (
          <button
            className="mt-8 py-2 px-4 bg-secondary text-white rounded-full"
            onClick={toggleShowAll}
          >
            {showAll ? 'View Less' : 'View More'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Home;
