import React, { useState, useEffect } from 'react';
import { generateKeys } from '../utils/api';
import { FaCopy, FaCheck } from 'react-icons/fa';
import axios from 'axios';

const ResellPanel = ({ currentUser }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [keys, setKeys] = useState([]);
  const [balance, setBalance] = useState(currentUser?.balance || 0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [products, setProducts] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/products');
        console.log('Fetched products data:', response.data);
    
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else {
          throw new Error('Products data is not in expected format.');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Error: Products data is not in expected format.');
      }
    };
    

    fetchProductsData();
  }, []);

  const handleGenerateKeys = async () => {
    if (!selectedProduct || !selectedPlan) {
      setError('Please select a product and a plan.');
      return;
    }

    const totalCost = selectedPlan.price * quantity;

    if (currentUser?.role === 'Reseller' && balance < totalCost) {
      setError('Insufficient balance to generate keys.');
      return;
    }

    try {
      setLoading(true);
      const result = await generateKeys(selectedProduct._id, quantity);
      setKeys(result.keys);

      if (currentUser?.role === 'Reseller') {
        setBalance(prevBalance => prevBalance - totalCost);
      }

      setError('');
    } catch (error) {
      console.error('Error generating keys:', error);
      setError('Error generating keys.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyKeys = () => {
    navigator.clipboard.writeText(keys.join('\n'));
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-text p-8">
      <div className="w-full max-w-4xl bg-gray-900 rounded-xl shadow-2xl p-10">
        <h1 className="text-5xl font-extrabold text-center text-primary mb-8">
          Reseller Panel
        </h1>

        {/* Balance Section */}
        <div className="flex justify-between items-center mb-8">
          <p className="text-2xl font-semibold text-gray-300">
            Balance: <span className="text-primary">${balance.toFixed(2)}</span>
          </p>
          {error && <p className="text-red-500">{error}</p>}
        </div>

        {/* Product Selection */}
        <div className="mb-8">
          <label htmlFor="product" className="block text-2xl font-semibold text-gray-300 mb-4">
            Select Product
          </label>
          <select
            id="product"
            className="w-full px-4 py-3 text-xl rounded-lg bg-gray-800 text-white border border-primary focus:outline-none focus:ring-4 focus:ring-primary"
            value={selectedProduct?._id || ''}
            onChange={(e) => {
              const product = products.find(p => p._id === e.target.value);
              setSelectedProduct(product);
              setSelectedPlan(null);
            }}
          >
            <option value="" disabled>Select a product</option>
            {products.map((product) => (
              <option key={product._id} value={product._id}>
                {product.title}
              </option>
            ))}
          </select>
        </div>

        {/* Plan Selection */}
        {selectedProduct && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-primary mb-6">Choose a Plan</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {selectedProduct.pricing.map((plan, index) => (
                <button
                  key={index}
                  className={`p-6 rounded-lg border ${
                    selectedPlan === plan
                      ? 'border-primary bg-gray-800'
                      : 'border-gray-700 bg-gray-800'
                  } text-left hover:bg-gray-700 transition duration-300 transform hover:scale-105`}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <h3 className="text-xl font-semibold text-white">{plan.plan}</h3>
                  <p className="text-gray-400">{plan.duration}</p>
                  <p className="text-primary font-bold mt-2">${plan.price}</p>
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Quantity Input */}
        {selectedPlan && (
          <div className="mb-8">
            <label htmlFor="quantity" className="block text-2xl font-semibold">
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              className="w-full px-4 py-3 text-xl rounded-lg bg-gray-800 text-white border border-primary focus:outline-none focus:ring-4 focus:ring-primary"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(+e.target.value)}
            />
          </div>
        )}

        {/* Generate Keys Button */}
        {selectedPlan && (
          <button
            onClick={handleGenerateKeys}
            className={`w-full py-4 ${loading ? 'bg-gray-600' : 'bg-primary'} text-background rounded-full shadow-lg hover:bg-secondary transform transition-transform duration-300 hover:scale-105`}
            disabled={loading}
          >
            {loading ? 'Generating...' : 'Generate Keys'}
          </button>
        )}

        {/* Generated Keys Display */}
        {keys.length > 0 && (
          <div className="mt-8 bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-primary mb-4">Generated Keys</h2>
            <textarea
              readOnly
              className="w-full h-40 bg-gray-900 text-white rounded-lg p-4"
              value={keys.join('\n')}
            />
            <button
              onClick={handleCopyKeys}
              className="mt-4 py-3 px-6 w-full bg-secondary text-background rounded-full hover:bg-primary transition-all flex items-center justify-center"
            >
              {copySuccess ? (
                <span className="flex items-center">
                  <FaCheck className="mr-2" /> Copied!
                </span>
              ) : (
                <span className="flex items-center">
                  <FaCopy className="mr-2" /> Copy Keys
                </span>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResellPanel;
