import React from 'react';

const Tos = () => {
  return (
    <div className="min-h-screen bg-background text-text py-12 px-6">
      <div className="max-w-6xl mx-auto bg-gray-900 bg-opacity-75 rounded-lg shadow-lg p-8 space-y-12">
        <header className="text-center">
          <h1 className="text-5xl font-extrabold text-primary mb-4">Terms of Service - Stellar</h1>
        </header>

        {/* Introduction */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Introduction</h2>
          <p className="text-lg">
            Welcome to Stellar. By accessing or using our services, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, you must not access or use our services. Please read these terms carefully before using our services.
          </p>
        </section>

        {/* Acceptance of Terms */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Acceptance of Terms</h2>
          <p className="text-lg">
            By installing, accessing, or using our software and services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree, you must discontinue use of our services immediately.
          </p>
        </section>

        {/* Eligibility */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Eligibility</h2>
          <p className="text-lg">
            Our services are intended for users who are at least 18 years of age. By using our services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into this agreement.
          </p>
        </section>

        {/* License and Use of Service */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">License and Use of Service</h2>
          <p className="text-lg">
            We grant you a limited, non-exclusive, non-transferable license to access and use our services for personal, non-commercial purposes. You may not use our services for any unlawful or prohibited purpose.
          </p>
          <p className="text-lg">
            Unauthorized use of our services, including but not limited to unauthorized access to our systems, misuse of passwords, or misuse of any information posted on our website, is strictly prohibited.
          </p>
        </section>

        {/* Prohibited Activities */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Prohibited Activities</h2>
          <p className="text-lg">
            You agree not to engage in any of the following prohibited activities:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>Copying, distributing, or disclosing any part of the service without our prior written permission.</li>
            <li>Using any automated system to access the service in a manner that sends more request messages to our servers than a human can reasonably produce in the same period of time.</li>
            <li>Attempting to interfere with or compromise the system integrity or security, or decipher any transmissions to or from the servers running the service.</li>
            <li>Using any device, software, or routine that interferes with the proper working of the service.</li>
            <li>Attempting to decompile, reverse engineer, or disassemble any software or other products or processes accessible through the service.</li>
            <li>Engaging in any activity that could disable, overburden, or impair the functionality of the service.</li>
          </ul>
        </section>

        {/* User Content */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">User Content</h2>
          <p className="text-lg">
            You are solely responsible for any content you post or upload to our service. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content worldwide.
          </p>
          <p className="text-lg">
            You represent and warrant that you own or have the necessary rights, consents, and permissions to submit content and that such content does not violate any third-party rights.
          </p>
        </section>

        {/* Third-Party Terms of Service */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Disclaimer Regarding Third-Party Terms of Service</h2>
          <p className="text-lg">
            Our products and services are designed to enhance user experience and provide additional functionality. However, some of these products may not comply with the Terms of Service, policies, or guidelines of third-party platforms, including but not limited to Roblox Corporation.
          </p>
          <p className="text-lg">
            By using our products, you acknowledge that you understand and assume any risks associated with using these products on third-party platforms. Stellar is not responsible for any actions taken by third-party platforms, including account suspension, termination, or other consequences resulting from the use of our products.
          </p>
          <p className="text-lg">
            We encourage users to familiarize themselves with the Terms of Service of any third-party platform they use in conjunction with our products to ensure compliance and avoid potential issues.
          </p>
        </section>

        {/* Limitation of Liability */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Limitation of Liability</h2>
          <p className="text-lg">
            In no event shall Stellar, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the service or these Terms of Service.
          </p>
          <p className="text-lg">
            Our total liability to you for any claims arising from your use of the service is limited to the amount paid by you, if any, for accessing the service in the past six months.
          </p>
        </section>

        {/* User Liability Waiver */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">User Liability Waiver</h2>
          <p className="text-lg">
            By using our products, you agree to release, indemnify, and hold harmless Stellar, its affiliates, employees, and representatives from any claims, liabilities, damages, or expenses arising from your use of our products, including but not limited to, any violations of third-party platform terms of service.
          </p>
          <p className="text-lg">
            You acknowledge and assume all risks associated with using our products in ways that may violate the terms and policies of third-party platforms, including but not limited to, Roblox Corporation. Stellar will not be held liable for any actions taken by these platforms against your account or other consequences resulting from your use of our products.
          </p>
        </section>

        {/* Governing Law */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Governing Law</h2>
          <p className="text-lg">
            These Terms of Service and your use of the service shall be governed by and construed in accordance with the laws of the State of Michigan, without regard to its conflict of laws principles.
          </p>
          <p className="text-lg">
            Any legal action or proceeding arising under these Terms of Service shall be brought exclusively in the state or federal courts located in Michigan, and you consent to the personal jurisdiction and venue of such courts.
          </p>
        </section>

        {/* Changes to Terms */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Changes to Terms</h2>
          <p className="text-lg">
            We reserve the right to modify these Terms of Service at any time. Changes will become effective immediately upon posting to our website. Your continued use of the service after any changes indicates your acceptance of the new terms.
          </p>
        </section>

        {/* Termination */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Termination</h2>
          <p className="text-lg">
            We may terminate or suspend your access to the service at any time, with or without cause, with or without notice, effective immediately. Upon termination, your right to use the service will immediately cease.
          </p>
        </section>

        {/* Contact Us */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Contact Us</h2>
          <p className="text-lg">
            If you have any questions about these terms or our practices, please contact our support team.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Tos;
