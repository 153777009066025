import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Autolaunch from './pages/Autolaunch';
import ClothingBot from './pages/ClothingBot';
import Register from './pages/Register';
import Login from './pages/Login';
import UserDashboard from './pages/UserDashboard';
import AdminPanel from './pages/AdminPanel';
import Ticket from './pages/Ticket';
import Contact from './pages/Contact';
import Chat from './pages/Chat';
import ProtectedRoute from './components/ProtectedRoute';
import ResellPanel from './pages/ResellPanel';
import CodeEditorPage from './pages/CodeEditorPage';
import Tos from './pages/Tos';
import Privacy from './pages/Privacy';
import RefundPolicy from './pages/RefundPolicy';
import { fetchUserData, logoutUser, fetchMaintenanceStatus } from './utils/api';
import Maintenance from './pages/Maintenance';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [maintenanceStatus, setMaintenanceStatus] = useState({});

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const user = await fetchUserData();
        setCurrentUser(user);
      } catch (error) {
        console.warn('Error loading user data:', error);
        logoutUser();
        setCurrentUser(null);
      }
    };

    const loadMaintenanceStatus = async () => {
      try {
        const status = await fetchMaintenanceStatus();
        setMaintenanceStatus(status);
      } catch (error) {
        console.error('Error fetching maintenance status:', error);
      }
    };

    loadMaintenanceStatus();
    loadUserData();
  }, []);

  const handleLogout = () => {
    logoutUser();
    setCurrentUser(null);
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <div className="relative min-h-screen flex flex-col bg-background text-text overflow-hidden">
        <nav className="bg-background bg-opacity-90 backdrop-blur-md py-4 fixed w-full z-10 shadow-lg border-b border-primary">
          <div className="container mx-auto flex justify-between items-center px-6">
            <Link to="/" className="text-3xl font-bold text-primary">Stellar</Link>
            <div className="hidden md:flex space-x-8 items-center">
              <Link to="/product/autolaunch" className="text-lg font-medium text-text hover:text-primary transition duration-300">Autolaunch</Link>
              <Link to="/product/clothing-bot" className="text-lg font-medium text-text hover:text-primary transition duration-300">Clothing Bot</Link>
              <Link to="/contact" className="text-lg font-medium text-text hover:text-primary transition duration-300">Contact</Link>
              <Link to="/chat" className="text-lg font-medium text-text hover:text-primary transition duration-300">Chat</Link>

              {currentUser ? (
                <div className="relative">
                  <button
                    className="text-lg font-medium text-text hover:text-primary transition duration-300 focus:outline-none"
                    onClick={toggleMenu}
                  >
                    {currentUser.username || 'User'}
                  </button>
                  {isMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-background border border-primary rounded-lg shadow-lg py-2 z-20">
                      <Link to="/user-dashboard" className="block text-left text-lg font-medium text-text hover:text-primary transition duration-300 px-4 py-2" onClick={toggleMenu}>Dashboard</Link>
                      {(currentUser.role?.toLowerCase() === 'admin' || currentUser.role?.toLowerCase() === 'owner' || currentUser.role?.toLowerCase() === 'support') && (
                        <Link to="/admin-panel" className="block text-left text-lg font-medium text-text hover:text-primary transition duration-300 px-4 py-2" onClick={toggleMenu}>Admin Panel</Link>
                      )}
                      {['Reseller', 'Admin', 'Owner'].includes(currentUser.role) && (
                        <Link to="/resell-panel" className="block px-4 py-2 hover:bg-gray-700">Resell Panel</Link>
                      )}
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left text-lg font-medium text-text hover:text-primary transition duration-300 px-4 py-2"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to="/login"
                  className="bg-primary text-background py-2 px-4 rounded-full shadow-lg hover:bg-secondary transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Login
                </Link>
              )}
            </div>

            <div className="md:hidden">
              <button onClick={toggleMenu} className="text-primary focus:outline-none">
                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
              </button>
            </div>
          </div>

          {isMenuOpen && (
            <div className="md:hidden bg-background bg-opacity-90 backdrop-blur-md border-t border-primary shadow-lg">
              <Link to="/product/autolaunch" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Autolaunch</Link>
              <Link to="/product/clothing-bot" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Clothing Bot</Link>
              <Link to="/contact" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Contact</Link>
              <Link to="/chat" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Chat</Link>

              {currentUser ? (
                <>
                  <Link to="/user-dashboard" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Dashboard</Link>
                  {(currentUser.role === 'Admin' || currentUser.role === 'Owner') && (
                    <Link to="/admin-panel" className="block text-lg font-medium text-text hover:text-primary transition duration-300 py-2 px-6" onClick={toggleMenu}>Admin Panel</Link>
                  )}
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left bg-secondary text-background py-2 px-6 rounded-none shadow-lg hover:bg-primary transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  className="block w-full text-left bg-primary text-background py-2 px-6 rounded-none shadow-lg hover:bg-secondary transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={toggleMenu}
                >
                  Login
                </Link>
              )}
            </div>
          )}
        </nav>

        <main className="pt-24 container mx-auto px-6 pb-24 animate-fadeInFast">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/autolaunch" element={<Autolaunch />} />
            <Route path="/product/clothing-bot" element={<ClothingBot />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/code-editor" element={<CodeEditorPage />} />
            <Route path="/tos" element={<Tos />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/user-dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
            <Route path="/resell-panel" element={<ProtectedRoute requiredRole={['Reseller', 'Admin', 'Owner']}><ResellPanel /></ProtectedRoute>} />
            <Route path="/admin-panel" element={<ProtectedRoute requiredRole={['Admin', 'Owner']}><AdminPanel /></ProtectedRoute>} />
            <Route path="/ticket/:ticketId" element={<Ticket user={currentUser} />} />
          </Routes>
        </main>

        <footer className="w-full bg-background bg-opacity-95 text-center py-8 mt-16 relative bottom-0 border-t border-primary shadow-inner">
          <div className="container mx-auto px-4 flex flex-col items-center space-y-4">
            <div className="flex justify-center space-x-8 mb-4">
              <Link to="/tos" className="text-sm md:text-base font-medium text-text hover:text-primary transition duration-300">
                Terms of Service
              </Link>
              <Link to="/privacy" className="text-sm md:text-base font-medium text-text hover:text-primary transition duration-300">
                Privacy Policy
              </Link>
              <Link to="/refund-policy" className="text-sm md:text-base font-medium text-text hover:text-primary transition duration-300">
                Refund Policy
              </Link>
              <Link to="/contact" className="text-sm md:text-base font-medium text-text hover:text-primary transition duration-300">
                Contact
              </Link>
            </div>

            <div className="flex justify-center space-x-6 mb-4">
              {/* Social icons */}
              <a
                href="https://www.youtube.com/stellarnotmadethoughyet"
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary hover:text-primary transform hover:scale-110 transition duration-300"
              >
                <svg
                  className="w-8 h-8 md:w-10 md:h-10"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.499 6.203c-.273-1.028-1.073-1.838-2.108-2.103C19.523 3.5 12 3.5 12 3.5s-7.523 0-9.391.6c-1.035.265-1.835 1.075-2.108 2.103C0 8.077 0 12 0 12s0 3.923.501 5.797c.273 1.028 1.073 1.838 2.108 2.103 1.868.6 9.391.6 9.391.6s7.523 0 9.391-.6c1.035-.265 1.835-1.075 2.108-2.103C24 15.923 24 12 24 12s0-3.923-.501-5.797zM9.75 15.02V8.98L15.5 12l-5.75 3.02z" />
                </svg>
              </a>
            </div>

            <p className="text-sm md:text-base text-gray-400">
              &copy; {new Date().getFullYear()} Stellar. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
