import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { axiosInstance } from '../utils/api';
import { FaUser, FaLock, FaShieldAlt } from 'react-icons/fa';
// I have never spent more time on 1 thing ever before than working on this 1 singular fucking file fml
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [errors, setErrors] = useState({});
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Login form submitted');
    console.log('Username:', username);
    console.log('Password:', password ? '*****' : '(No password entered)');
    console.log('2FA Code:', twoFactorCode || '(No 2FA code entered)');
    
    setLoading(true);
    setErrors({});
    console.log('Loading set to true');

    try {
      const response = await axiosInstance.post('/auth/login', { username, password, twoFactorCode });
      console.log('Login response received:', response);

      if (response.data.token) {
        const token = response.data.token;
        console.log('Token received:', token);
        localStorage.setItem('auth_token', token);

        console.log('Token stored in localStorage, navigating to user dashboard');
        navigate('/user-dashboard');
      } else {
        console.error('No token found in the response');
        setErrors({ api: 'No token found in response. Please try again.' });
      }
    } catch (error) {
      console.error('Login error:', error);

      if (error.response?.data?.twoFactorEnabled) {
        setTwoFactorRequired(true);
        console.log('2FA required');
        setErrors({ twoFactor: '2FA code is required. Please enter your code.' });
      } else {
        setErrors(error.response?.data?.errors || { api: error.response?.data?.message || 'An unexpected error occurred.' });
        console.log('Errors set:', error.response?.data?.errors || { api: error.response?.data?.message });
      }
    } finally {
      setLoading(false);
      console.log('Loading set to false');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-gray-800 to-black text-text py-12 px-6">
      <div className="w-full max-w-lg bg-gray-900 bg-opacity-80 rounded-lg shadow-lg p-10 space-y-12">
        <header className="text-center mb-6">
          <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400 mb-4">
            Welcome Back!
          </h1>
          <p className="text-gray-300 text-lg">
            Log in to access your dashboard and enjoy our features.
          </p>
        </header>

        {errors.api && (
          <div className="bg-red-600 text-white p-4 rounded-lg text-center shadow-lg animate-fadeIn">
            {errors.api}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="relative">
            <label htmlFor="username" className="block text-lg font-semibold text-text">
              Username
            </label>
            <div className="mt-2 flex items-center relative">
              <FaUser className="absolute ml-3 text-gray-400" />
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => {
                  console.log('Username updated:', e.target.value);
                  setUsername(e.target.value);
                }}
                className={`w-full pl-10 py-3 text-white bg-gray-800 border ${errors.username ? 'border-red-500' : 'border-gray-600'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300`}
                placeholder="Enter your username"
              />
            </div>
            {errors.username && <p className="text-red-500 text-sm mt-2">{errors.username}</p>}
          </div>

          <div className="relative">
            <label htmlFor="password" className="block text-lg font-semibold text-text">
              Password
            </label>
            <div className="mt-2 flex items-center relative">
              <FaLock className="absolute ml-3 text-gray-400" />
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => {
                  console.log('Password updated');
                  setPassword(e.target.value);
                }}
                className={`w-full pl-10 py-3 text-white bg-gray-800 border ${errors.password ? 'border-red-500' : 'border-gray-600'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300`}
                placeholder="Enter your password"
              />
            </div>
            {errors.password && <p className="text-red-500 text-sm mt-2">{errors.password}</p>}
          </div>

          {twoFactorRequired && (
            <div className="relative">
              <label htmlFor="twoFactorCode" className="block text-lg font-semibold text-text">
                2FA Code
              </label>
              <div className="mt-2 flex items-center relative">
                <FaShieldAlt className="absolute ml-3 text-gray-400" />
                <input
                  type="text"
                  id="twoFactorCode"
                  value={twoFactorCode}
                  onChange={(e) => {
                    console.log('2FA Code updated:', e.target.value);
                    setTwoFactorCode(e.target.value);
                  }}
                  className="w-full pl-10 py-3 text-white bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300"
                  placeholder="Enter your 2FA code"
                />
              </div>
              {errors.twoFactor && <p className="text-red-500 text-sm mt-2">{errors.twoFactor}</p>}
            </div>
          )}

          <button
            type="submit"
            className="w-full py-3 rounded-lg bg-gradient-to-r from-teal-500 to-blue-500 text-white font-semibold shadow-lg transform transition-transform duration-300 hover:scale-105 focus:ring-4 focus:ring-teal-500 disabled:opacity-50 flex items-center justify-center"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>

        <div className="text-center mt-8 space-y-4">
          <p className="text-text">
            <Link to="/register" className="text-teal-400 hover:underline">
              Don't have an account? Register here
            </Link>
          </p>
          <p className="text-gray-400 text-sm">
            Forgot your password?{' '}
            <Link to="/reset-password" className="text-teal-400 hover:underline">
              Reset it here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
