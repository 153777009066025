import React from 'react';
import { FaTag } from 'react-icons/fa';
import { products } from '../data/products';

const Autolaunch = () => {
  const product = products.find((p) => p.title === 'Autolaunch');

  if (!product) {
    return (
      <div className="min-h-screen bg-background text-text py-12 px-6 flex items-center justify-center">
        <h1 className="text-3xl font-bold text-primary">Product Not Found</h1>
      </div>
    );
  }

  const calculateOriginalPrice = (duration, price) => {
    if (duration === '1 Year') return price * 2;
    if (duration === '1 Month') return price * (28 / 7);
    return price;
  };

  const calculateDiscountPercentage = (original, discounted) =>
    Math.round(((original - discounted) / original) * 100);

  return (
    <div className="min-h-screen bg-background text-text py-8 px-4 sm:px-6 md:px-12 flex flex-col items-center">
      <div className="max-w-7xl w-full bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-2xl p-12 space-y-16 hover:shadow-3xl hover:scale-105 transition-all duration-500">
        
        {/* Header Section */}
        <header className="text-center space-y-6">
          <h1 
            className="text-5xl sm:text-6xl md:text-7xl font-extrabold bg-clip-text text-transparent 
                       bg-gradient-to-r from-indigo-500 via-pink-400 to-purple-500 drop-shadow-lg">
            {product.title}
          </h1>
          <p className="text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto text-gray-300 leading-relaxed font-medium">
            {product.description}
          </p>
        </header>

        {/* Features Section */}
        <section className="space-y-10">
          <h2 className="text-4xl md:text-5xl font-bold text-primary text-center">
            Key Features
          </h2>
          <ul className="list-none space-y-6 text-lg md:text-xl text-gray-300">
            {product.features.map((feature, index) => (
              <li
                key={index}
                className="bg-gray-800 bg-opacity-60 p-5 rounded-lg shadow-md flex items-center space-x-4 
                           transition-transform transform hover:scale-105 hover:shadow-lg hover:bg-opacity-70"
              >
                <div className="w-3 h-full bg-primary rounded-md"></div>
                <span className="font-semibold">{feature}</span>
              </li>
            ))}
          </ul>
        </section>

        {/* Pricing Section */}
        <section className="space-y-12">
          <h2 className="text-4xl md:text-5xl font-bold text-primary text-center">
            Pricing Options
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {product.pricing.map((plan, index) => {
              const originalPrice = calculateOriginalPrice(plan.duration, plan.price);
              const discountPercentage =
                originalPrice !== plan.price
                  ? calculateDiscountPercentage(originalPrice, plan.price)
                  : null;

              return (
                <div
                  key={index}
                  className="relative bg-gray-800 bg-opacity-80 p-8 rounded-xl shadow-lg flex flex-col justify-between 
                             hover:scale-105 hover:shadow-xl transition-all duration-300 group"
                >
                  {discountPercentage && (
                    <div className="absolute top-0 left-0 bg-teal-500 text-white text-sm font-semibold px-4 py-1 rounded-tr-lg rounded-bl-lg shadow-md">
                      Save {discountPercentage}%!
                    </div>
                  )}

                  <div className="flex items-center justify-center mb-6">
                    <FaTag className="text-teal-400 mr-3 text-3xl group-hover:text-gray-200 transition-all duration-300" />
                    <h3 className="text-3xl md:text-4xl font-bold text-white">{plan.plan}</h3>
                  </div>

                  <p className="text-lg md:text-xl text-gray-400 mb-2 text-center font-medium">
                    {plan.duration}
                  </p>

                  {plan.duration !== '1 Week' && originalPrice !== plan.price && (
                    <p className="text-xl md:text-2xl text-gray-400 line-through text-center">
                      ${originalPrice.toFixed(2)}
                    </p>
                  )}

                  <p className="text-4xl md:text-5xl font-extrabold text-primary text-center mb-2">
                    ${plan.price.toFixed(2)}
                  </p>

                  <button
                    className="w-full bg-gradient-to-r from-indigo-500 to-teal-500 text-white py-3 px-6 rounded-full 
                               shadow-md hover:bg-gradient-to-l hover:from-teal-500 hover:to-indigo-500 hover:text-background 
                               transition-all duration-300 mt-auto"
                  >
                    Subscribe Now
                  </button>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Autolaunch;
