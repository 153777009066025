const products = [
  {
    _id: "64e4f5f7a7c21370f40f4567",
    title: "Autolaunch",
    description: "Unlock the future of botting with AutoLaunch, designed to help you manage more bots effortlessly and maximize your efficiency.",
    features: [
      "Seamless automation for launching multiple bots simultaneously",
      "Optimized Roblox performance to support more tabs with ease",
      "Automatic detection and replacement of banned accounts",
      "Hassle-free reinstatement of Roblox accounts",
      "Full integration with Python for advanced scripting support",
      "Packed with cutting-edge features to enhance your botting experience",
    ],
    pricing: [
      { plan: "Basic Plan", duration: "1 Week", price: 7 },
      { plan: "Standard Plan", duration: "1 Month", price: 25 },
      { plan: "Premium Plan", duration: "1 Year", price: 150 },
    ],
  },
  {
    _id: "64e4f5f7a7c21370f40f4568",
    title: "Clothing Bot",
    description: "Elevate your Roblox fashion game with our advanced Clothing Bot, the ultimate tool for managing and optimizing your clothing business.",
    features: [
      "Effortless bulk uploads for all your clothing designs",
      "Comprehensive sales analytics to track your earnings in detail",
      "Powerful bulk editing tools to streamline your workflow",
      "AI-driven quality control to ensure top-tier clothing designs every time",
    ],
    pricing: [
      { plan: "Basic Plan", duration: "1 Week", price: 7 }, 
      { plan: "Standard Plan", duration: "1 Month", price: 20 }, 
      { plan: "Premium Plan", duration: "1 Year", price: 120 },
    ],
  },
  {
    _id: "64e4f5f7a7c21370f40f4569",
    title: "Other",
    description: "Stay tuned! We’re constantly innovating and may offer additional exciting products in the near future.",
    features: [
      "Innovative placeholders for future features",
      "Cutting-edge technologies in the pipeline",
    ],
    pricing: [
      { plan: "Basic Plan", duration: "1 Week", price: 7 },
      { plan: "Standard Plan", duration: "1 Month", price: 20 },
      { plan: "Premium Plan", duration: "1 Year", price: 50 },
    ],
  },
];

module.exports = { products };
