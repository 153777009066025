import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Maintenance = () => {
  const navigate = useNavigate();

  const goBackHome = () => {
    navigate('/');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-800 via-gray-900 to-black text-text p-6 md:p-8">
      <div className="flex flex-col items-center space-y-8 md:space-y-10 text-center max-w-md md:max-w-xl">
        {/* Animated Wrench Icon */}
        <div className="relative">
          <FaWrench className="text-6xl md:text-8xl text-primary animate-pulse drop-shadow-lg" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-20 h-20 md:w-28 md:h-28 border-4 border-primary/40 border-dashed rounded-full animate-spin-slow" />
          </div>
        </div>

        <h1 className="text-4xl md:text-6xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-400 drop-shadow-lg leading-tight">
          We’ll Be Right Back!
        </h1>

        <p className="text-lg md:text-xl text-gray-300 leading-relaxed px-2">
          Our site is currently undergoing scheduled maintenance to serve you better.
        </p>

        <p className="text-sm md:text-lg text-gray-400">
          Thank you for your patience. We’ll be back online shortly!
        </p>

        <button
          className="py-3 px-8 md:py-4 md:px-10 text-lg md:text-xl font-semibold text-white bg-gradient-to-r from-pink-500 to-red-500 rounded-full shadow-xl 
            hover:from-red-500 hover:to-pink-500 hover:scale-110 transform transition-all duration-300 ease-in-out focus:ring-4 focus:ring-pink-400"
          onClick={goBackHome}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Maintenance;
