import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTicketDetails, fetchChatsForTicket, postChatToTicket, escalateTicket, deleteTicket } from '../utils/api';
import { format, parseISO } from 'date-fns';
import { FaArrowCircleUp, FaTrash, FaPaperPlane } from 'react-icons/fa';

const Ticket = ({ user }) => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');
  const [role, setRole] = useState(user?.role === 'Support' || user?.role === 'Admin' || user?.role === 'Owner' ? 'Support' : 'Username');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTicketAndChats = async () => {
      try {
        const ticketData = await fetchTicketDetails(ticketId);
        setTicket(ticketData);

        const chatData = await fetchChatsForTicket(ticketId);
        if (Array.isArray(chatData)) {
          setChats(chatData.map(chat => ({ ...chat, timestamp: chat.timestamp || new Date().toISOString() })));
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching ticket or chats:', error);
        setError('Failed to load ticket details.');
        setLoading(false);
      }
    };

    fetchTicketAndChats();
  }, [ticketId]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    if (!user?.username) {
      setError("User data is missing. Please refresh the page.");
      return;
    }

    try {
      const chatData = {
        sender: user.username,
        role,
        message,
      };
      const response = await postChatToTicket(ticketId, chatData);

      setChats([...chats, response]);
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message.');
    }
  };

  const handleEscalate = async () => {
    try {
      await escalateTicket(ticketId);
      setTicket({ ...ticket, escalated: true });
    } catch (error) {
      console.error('Error escalating ticket:', error);
    }
  };

  const handleDeleteTicket = async () => {
    try {
      await deleteTicket(ticketId);
    } catch (error) {
      console.error('Error deleting ticket:', error);
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'yyyy-MM-dd HH:mm:ss');
    } catch {
      return 'Invalid Date';
    }
  };

  if (loading) return <div className="flex items-center justify-center min-h-screen text-2xl">Loading ticket details...</div>;
  if (!ticket) return <div className="flex items-center justify-center min-h-screen text-2xl">Ticket not found.</div>;

  return (
    <div className="container mx-auto p-6 bg-gray-900 text-white rounded-lg shadow-lg mt-10">
      <h1 className="text-5xl font-bold text-gradient mb-8 text-center">Ticket Details</h1>
      <div className="bg-gradient-to-r from-purple-700 to-indigo-700 p-8 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl font-semibold text-white mb-4">{ticket.username}</h2>
        <div className="space-y-4">
          <p className="text-lg"><strong>Transaction ID:</strong> {ticket.transactionId}</p>
          <p className="text-lg"><strong>Priority:</strong> {ticket.priority}</p>
          <p className="text-lg"><strong>Reason:</strong> {ticket.reason}</p>
          <p className="text-lg"><strong>Message:</strong> {ticket.message}</p>
          {ticket.escalated && <p className="text-lg text-red-400"><strong>Status:</strong> Escalated</p>}
        </div>
        <div className="flex space-x-4 mt-6">
          <button
            onClick={handleDeleteTicket}
            className="flex items-center bg-red-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-red-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            <FaTrash className="mr-2" /> Delete Ticket
          </button>
        </div>
      </div>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-3xl font-semibold text-gradient mb-4">Chat</h2>
        <div className="space-y-4 mb-4 overflow-auto max-h-96 custom-scrollbar">
          {chats.map((chat) => (
            <div key={chat._id} className="bg-gradient-to-r from-gray-700 to-gray-800 p-4 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-2">
                <p className="text-sm text-gray-400">
                  {chat.role === 'Username' ? chat.sender : chat.role}
                  {user?.role === 'Admin' && chat.role !== 'Username' ? ` (${chat.sender})` : ''}
                </p>
                <p className="text-xs text-gray-500">{formatDate(chat.timestamp)}</p>
              </div>
              <p className="text-lg text-gray-200">{chat.message}</p>
            </div>
          ))}
        </div>
        <div className="flex space-x-4">
          {['Support', 'Admin', 'Owner'].includes(user?.role) && (
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="bg-gray-700 border border-primary rounded-md p-2 text-text"
            >
              <option value="Username">Username</option>
              <option value="Support">Support</option>
            </select>
          )}
          <input
            type="text"
            className="flex-1 px-4 py-2 rounded-lg bg-gray-700 text-text border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
          />
          <button
            onClick={handleSendMessage}
            className="flex items-center bg-primary text-background py-2 px-6 rounded-lg shadow-lg hover:bg-secondary transition duration-300 ease-in-out transform hover:scale-105"
          >
            <FaPaperPlane className="mr-2" /> Send
          </button>
          {['Support', 'Admin', 'Owner'].includes(user?.role) && !ticket.escalated && (
            <button
              onClick={handleEscalate}
              className="flex items-center bg-yellow-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-yellow-600 transition duration-300 ease-in-out transform hover:scale-105"
            >
              <FaArrowCircleUp className="mr-2" /> Escalate
            </button>
          )}
        </div>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default Ticket;
