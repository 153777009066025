import React from 'react';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-background text-text py-12 px-6">
      <div className="max-w-6xl mx-auto bg-gray-900 bg-opacity-75 rounded-lg shadow-lg p-8 space-y-12">
        <header className="text-center">
          <h1 className="text-5xl font-extrabold text-primary mb-4">Privacy Policy - Stellar</h1>
        </header>

        {/* Introduction */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Introduction</h2>
          <p className="text-lg">
            At Stellar, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and safeguard the information you provide when using our software and services. By accessing or using our services, you agree to the terms of this policy.
          </p>
        </section>

        {/* Information Collection */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Information We Collect</h2>
          <h3 className="text-2xl font-semibold text-secondary">1. Personal Information</h3>
          <p className="text-lg">
            We may collect personal information such as your name, email address, payment details, and other identifying information when you register for our services, make a purchase, or interact with our support team. This information is used to manage your account, process transactions, and provide customer support.
          </p>

          <h3 className="text-2xl font-semibold text-secondary">2. Usage Data</h3>
          <p className="text-lg">
            We collect data on how you use our software and services, including access times, features used, and software performance metrics. This information helps us understand how our users interact with our services and identify areas for improvement.
          </p>

          <h3 className="text-2xl font-semibold text-secondary">3. Device and Technical Information</h3>
          <p className="text-lg">
            We may collect technical information about the devices you use to access our services, including IP address, device type, operating system, and browser type. This information is used to optimize the performance and security of our services.
          </p>

          <h3 className="text-2xl font-semibold text-secondary">4. Cookies and Tracking Technologies</h3>
          <p className="text-lg">
            We use cookies, web beacons, and similar technologies to collect information about your use of our services, improve your experience, and analyze trends. You can control the use of cookies through your browser settings, but disabling cookies may affect your ability to use certain features of our services.
          </p>
        </section>

        {/* How We Use Information */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">How We Use Your Information</h2>
          <p className="text-lg">
            The information we collect is used for the following purposes:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>To provide, operate, and maintain our services.</li>
            <li>To personalize and improve your experience with our services.</li>
            <li>To communicate with you regarding updates, promotions, and support.</li>
            <li>To monitor and analyze usage and trends to improve the quality of our services.</li>
            <li>To prevent, detect, and address fraud, security breaches, and other prohibited activities.</li>
            <li>To comply with legal obligations and enforce our terms and policies.</li>
          </ul>
        </section>

        {/* Data Sharing and Disclosure */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Data Sharing and Disclosure</h2>
          <p className="text-lg">
            We do not sell, trade, or rent your personal information to third parties. We may share your information in the following circumstances:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>
              <strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our services, conducting our business, or serving our users. These service providers are required to maintain the confidentiality of your information and are prohibited from using it for any purpose other than providing their services.
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to a legal process, such as a subpoena, court order, or request from a law enforcement agency.
            </li>
            <li>
              <strong>Business Transfers:</strong> If Stellar is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you of any such change in ownership or control of your personal information.
            </li>
          </ul>
        </section>

        {/* Data Security */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Data Security</h2>
          <p className="text-lg">
            We take data security seriously and implement a variety of security measures to protect your personal information from unauthorized access, use, or disclosure. These measures include encryption, access controls, and secure data storage.
          </p>
          <p className="text-lg">
            While we strive to protect your personal information, no security system is completely secure. We cannot guarantee the absolute security of your information and encourage you to take steps to protect your data, such as using strong passwords and keeping your account information confidential.
          </p>
        </section>

        {/* Data Retention */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Data Retention</h2>
          <p className="text-lg">
            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When your information is no longer needed, we will securely delete or anonymize it.
          </p>
        </section>

        {/* International Data Transfers */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">International Data Transfers</h2>
          <p className="text-lg">
            Your information may be transferred to, and maintained on, servers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction. By using our services, you consent to the transfer of your information to the United States and other jurisdictions as necessary.
          </p>
        </section>

        {/* Your Rights and Choices */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Your Rights and Choices</h2>
          <p className="text-lg">
            Depending on your location, you may have the following rights regarding your personal data:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>The right to access and request a copy of your personal data.</li>
            <li>The right to request correction or deletion of your personal data.</li>
            <li>The right to object to or restrict the processing of your personal data.</li>
            <li>The right to data portability.</li>
            <li>The right to withdraw your consent to data processing at any time.</li>
            <li>The right to lodge a complaint with a data protection authority if you believe your rights have been violated.</li>
          </ul>
          <p className="text-lg">
            To exercise these rights, please contact our support team.
          </p>
        </section>

        {/* Children's Privacy */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Children's Privacy</h2>
          <p className="text-lg">
            Our services are not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete that information as soon as possible. If you believe that we may have collected information from a child under 13, please contact our support team.
          </p>
        </section>

        {/* Changes to This Privacy Policy */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Changes to This Privacy Policy</h2>
          <p className="text-lg">
            We may update this Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements, or other factors. When we make changes, we will update the "Last Updated" date at the top of this policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
          </p>
        </section>

        {/* Contact Us */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Contact Us</h2>
          <p className="text-lg">
            If you have any questions about this Privacy Policy or our data practices, please contact our support team.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
