import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="min-h-screen bg-background text-text py-12 px-6">
      <div className="max-w-6xl mx-auto bg-gray-900 bg-opacity-75 rounded-lg shadow-lg p-8 space-y-12">
        <header className="text-center">
          <h1 className="text-5xl font-extrabold text-primary mb-4">Refund Policy - Stellar</h1>
        </header>

        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Refunds and Cancellations</h2>
          <p className="text-lg">
            We understand that circumstances may change, and we strive to provide a fair and transparent refund policy. Refunds are available only under specific conditions outlined below:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>If a product or service is discontinued entirely by Stellar within 48 hours of purchase, you may opt for a full refund or select another product of equal value offered by Stellar.</li>
            <li>Refund requests must be submitted within 48 hours of purchase and must include a valid reason for the request.</li>
            <li>We do not offer refunds for services that have already been delivered or used, unless the service is found to be defective or not as described.</li>
          </ul>
        </section>

        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Chargebacks</h2>
          <p className="text-lg">
            Chargebacks occur when a customer disputes a charge to their payment card issuer. We encourage customers to contact our support team to resolve billing issues before initiating a chargeback.
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2 text-lg">
            <li>Unjustified chargebacks may lead to termination of your service and a ban from future use of our products.</li>
            <li>If a chargeback is found to be fraudulent, we reserve the right to take legal action to recover any losses incurred as a result.</li>
          </ul>
        </section>

        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-primary">Contact for Refunds</h2>
          <p className="text-lg">
            If you wish to request a refund or have questions regarding our refund policy, please contact our support team.
          </p>
        </section>
      </div>
    </div>
  );
};

export default RefundPolicy;
