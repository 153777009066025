import React, { useState, useRef } from 'react';
import Editor, { loader } from '@monaco-editor/react';
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  File as FileIcon,
  Save as SaveIcon,
  Play as PlayIcon,
  X as ClearIcon,
  PlugZap as AttachIcon,
  Folder as FolderIcon,
  Bookmark as BookmarkIcon,
  Archive as ArchiveIcon,
} from 'lucide-react';

loader.config({ paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.30.1/min/vs' } });

const TABS = ['editor', 'settings', 'scriptHub', 'account'];

const CodeEditorPage = () => {
  const [activeTab, setActiveTab] = useState('editor');
  const [isAttached, setIsAttached] = useState(false);
  const editorRef = useRef(null);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  const handleExecute = () => {
    const code = editorRef.current.getValue();
    console.log('Executing code:', code);
  };

  const handleClear = () => {
    editorRef.current.setValue('');
  };

  const handleAttach = () => {
    // Placeholder logic for attach action
    setIsAttached(true);
  };

  const renderEditorScreen = () => (
    <div className="flex flex-1">
      <div className="flex flex-col flex-grow p-6">
        <Editor
          height="400px"
          theme="vs-dark"
          language="lua"
          defaultValue="print('Helix on top')"
          onMount={handleEditorDidMount}
          options={{
            fontSize: 16,
            minimap: { enabled: false },
            smoothScrolling: true,
            scrollbar: {
              verticalScrollbarSize: 8,
              horizontalScrollbarSize: 8,
            },
            lineHeight: 24,
            cursorSmoothCaretAnimation: true,
            cursorBlinking: 'phase',
            padding: { top: 10 },
          }}
        />
        <footer className="bg-[#1a1a1a] px-8 py-4 flex justify-between items-center border-t border-[#333] mt-4">
          <div className="flex space-x-4">
            <button
              onClick={handleExecute}
              className="w-14 h-14 bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#3a3a3a] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-105"
              title="Execute"
            >
              <PlayIcon size={22} />
            </button>
            <button
              onClick={handleClear}
              className="w-14 h-14 bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#3a3a3a] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-105"
              title="Clear"
            >
              <ClearIcon size={22} />
            </button>
            <button
              onClick={() => alert('Open file')}
              className="w-14 h-14 bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#3a3a3a] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-105"
              title="Open File"
            >
              <FileIcon size={22} />
            </button>
            <button
              onClick={() => alert('Save file')}
              className="w-14 h-14 bg-[#2c2c2c] border-[#2c2c2c] hover:bg-[#3a3a3a] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-105"
              title="Save File"
            >
              <SaveIcon size={22} />
            </button>
          </div>
          <button
            onClick={handleAttach}
            disabled={isAttached}
            className={`w-14 h-14 ${
              isAttached ? 'bg-[#555]' : 'bg-[#2c2c2c]'
            } border-[#2c2c2c] hover:bg-[#3a3a3a] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-105`}
            title="Attach"
          >
            <AttachIcon size={22} />
          </button>
        </footer>
      </div>

      <aside className="w-60 bg-[#202020] border-l border-[#333] flex flex-col p-4">
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg text-white">Local Files</span>
          <FolderIcon size={20} />
        </div>
        <div className="flex flex-col space-y-2">
          <button className="text-left text-sm text-white bg-[#333] px-4 py-2 rounded-md hover:bg-[#444]">
            Script 1
          </button>
          <button className="text-left text-sm text-white bg-[#333] px-4 py-2 rounded-md hover:bg-[#444]">
            Script 2
          </button>
          <button className="text-left text-sm text-white bg-[#333] px-4 py-2 rounded-md hover:bg-[#444]">
            Script 3
          </button>
        </div>

        <div className="mt-6 flex justify-between items-center mb-4">
          <span className="text-lg text-white">Bookmarks</span>
          <BookmarkIcon size={20} />
        </div>
        <div className="flex flex-col space-y-2">
          <button className="text-left text-sm text-white bg-[#333] px-4 py-2 rounded-md hover:bg-[#444]">
            Pinned Script 1
          </button>
        </div>

        <div className="mt-6 flex justify-between items-center mb-4">
          <span className="text-lg text-white">Autoexec</span>
          <ArchiveIcon size={20} />
        </div>
        <div className="flex flex-col space-y-2">
          <button className="text-left text-sm text-white bg-[#333] px-4 py-2 rounded-md hover:bg-[#444]">
            Autoexec Script 1
          </button>
        </div>
      </aside>
    </div>
  );

  const renderSettingsScreen = () => (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="text-xl font-bold text-white mb-4">Settings</div>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4">
          <span>Enable Feature</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer bg-[#333] peer-checked:bg-blue-600"></div>
          </label>
        </div>
        <div className="flex items-center space-x-4">
          <span>Dropdown Option 1</span>
          <select className="bg-[#2c2c2c] text-white rounded-md px-4 py-2 border border-[#333]">
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#181818] text-white flex flex-col items-center justify-center p-8">
      <div className="w-full max-w-5xl bg-[#202020] rounded-lg shadow-2xl overflow-hidden">
        <header className="bg-[#1a1a1a] px-8 py-5 flex justify-between items-center border-b border-[#333]">
          <h1 className="text-4xl font-extrabold text-[#fff]">Helix</h1>
          <div className="flex space-x-4">
            {TABS.map((tab) => (
              <button
                key={tab}
                className={`w-12 h-12 ${
                  activeTab === tab ? 'bg-[#444]' : 'bg-[#333]'
                } hover:bg-[#444] text-white flex items-center justify-center rounded-md transition-transform transform hover:scale-110`}
                onClick={() => setActiveTab(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab === 'editor' && <HomeIcon size={22} />}
                {tab === 'settings' && <SettingsIcon size={22} />}
                {tab === 'scriptHub' && <FileIcon size={22} />}
                {tab === 'account' && <ArchiveIcon size={22} />}
              </button>
            ))}
          </div>
        </header>

        {activeTab === 'editor'
          ? renderEditorScreen()
          : activeTab === 'settings'
          ? renderSettingsScreen()
          : null}
      </div>
    </div>
  );
};

export default CodeEditorPage;
