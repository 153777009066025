import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Maintenance = () => {
  const navigate = useNavigate();

  const goBackHome = () => {
    navigate('/');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-black text-text p-6">
      <div className="flex flex-col items-center space-y-8 text-center">
        {/* Wrench Icon */}
        <FaWrench className="text-7xl text-primary animate-spin-slow" />
        <h1 className="text-6xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
          We’ll Be Right Back!
        </h1>
        <p className="text-2xl text-gray-300">
          Our site is currently undergoing maintenance.
        </p>
        <p className="text-lg text-gray-400">
          We’re working hard to improve your experience. Please check back soon.
        </p>

        <button
          className="py-4 px-12 text-lg font-semibold text-white bg-gradient-to-r from-primary to-secondary rounded-full shadow-lg
          hover:from-secondary hover:to-primary hover:scale-105 transform transition duration-300 ease-in-out"
          onClick={goBackHome}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Maintenance;
