import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTicket, fetchUserData } from '../utils/api';
import { FaExclamationCircle, FaArrowDown, FaPaperPlane } from 'react-icons/fa';

const Contact = () => {
  const [user, setUser] = useState(null);
  const [transactionId, setTransactionId] = useState('');
  const [message, setMessage] = useState('');
  const [priority, setPriority] = useState('Low');
  const [reason, setReason] = useState('General Inquiry');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await fetchUserData();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    getUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setErrors({ api: 'User data is not loaded yet. Please try again later.' });
      return;
    }

    setLoading(true);
    setErrors({});

    const ticketData = {
      username: user.username,
      userId: user._id,
      transactionId,
      message,
      priority,
      reason,
    };

    try {
      const response = await createTicket(ticketData);
      navigate(`/ticket/${response.id}`);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error submitting ticket';
      setErrors({ api: errorMessage });
      console.error('Error submitting ticket:', errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background text-text flex items-center justify-center py-16 px-6">
      <div className="w-full max-w-4xl bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-2xl shadow-2xl p-10 space-y-10 transition-all transform hover:shadow-3xl hover:scale-[1.02]">
        {/* Header */}
        <header className="text-center space-y-4">
          <h1 className="text-5xl font-extrabold text-primary bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 drop-shadow-md mb-6">
            Contact Support
          </h1>
          <p className="text-xl text-gray-300 max-w-xl mx-auto leading-relaxed">
            If you have any questions, issues, or need support, please fill out the form below, and our team will get back to you as soon as possible.
          </p>
        </header>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-8">
          {errors.api && (
            <div className="flex items-center justify-center text-red-500 mb-4 bg-red-100 p-4 rounded-lg border border-red-400 shadow-sm">
              <FaExclamationCircle className="mr-2 text-xl" />
              <span className="text-lg">{errors.api}</span>
            </div>
          )}

          <div className="space-y-2">
            <label htmlFor="transactionId" className="text-lg font-semibold text-primary">Transaction ID</label>
            <input
              type="text"
              id="transactionId"
              className="w-full px-4 py-3 rounded-lg bg-gray-800 text-text border border-primary focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              placeholder="Enter your transaction ID (if applicable)"
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="priority" className="text-lg font-semibold text-primary">Priority</label>
            <div className="relative">
              <select
                id="priority"
                className="w-full px-4 py-3 rounded-lg bg-gray-800 text-text border border-primary appearance-none focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </select>
              <FaArrowDown className="absolute inset-y-0 right-4 my-auto pointer-events-none text-gray-500" />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="reason" className="text-lg font-semibold text-primary">Reason</label>
            <div className="relative">
              <select
                id="reason"
                className="w-full px-4 py-3 rounded-lg bg-gray-800 text-text border border-primary appearance-none focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="General Inquiry">General Inquiry</option>
                <option value="Bug Report">Bug Report</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Billing Issue">Billing Issue</option>
              </select>
              <FaArrowDown className="absolute inset-y-0 right-4 my-auto pointer-events-none text-gray-500" />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="message" className="text-lg font-semibold text-primary">Message</label>
            <textarea
              id="message"
              className="w-full px-4 py-3 rounded-lg bg-gray-800 text-text border border-primary focus:outline-none focus:ring-2 focus:ring-primary transition-all duration-300"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="6"
              placeholder="Describe your issue or inquiry in detail..."
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-gradient-to-r from-purple-600 to-blue-500 text-white rounded-full shadow-lg hover:bg-gradient-to-l hover:from-blue-500 hover:to-purple-600 transform hover:scale-105 transition duration-300 ease-in-out flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? (
              'Submitting...'
            ) : (
              <>
                <FaPaperPlane className="mr-2" />
                Submit Request
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
