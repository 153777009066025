import React, { useState, useEffect } from 'react';
import {
  setup2FA,
  verify2FA,
  fetchUserData,
  fetchUserTickets,
  deleteTicket,
  redeemKey,
  fetchOwnedProducts, // Import the new function for fetching owned products
  resetHWID,
} from '../utils/api';
import Modal from 'react-modal';
import { FaTrash, FaTicketAlt, FaLock, FaKey, FaCog, FaSync } from 'react-icons/fa';

Modal.setAppElement('#root');

const UserDashboard = () => {
  const [user, setUser] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [products, setProducts] = useState([]); // State for user products
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [twoFactorSetup, setTwoFactorSetup] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);
  const [redeemKeyCode, setRedeemKeyCode] = useState(''); // State for key redemption
  const [redeemError, setRedeemError] = useState(null); // Error for redeeming keys
  const [redeeming, setRedeeming] = useState(false); // Loading state for redeeming keys

  // Function to load user data, tickets, and products
  const fetchUserAndTickets = async () => {
    try {
      setLoading(true);
      console.log('Fetching user data...');
      const userData = await fetchUserData();
      setUser(userData);
      setIsVerified(userData.twoFactorEnabled);

      console.log('Fetching tickets...');
      const ticketsData = await fetchUserTickets(userData._id);
      setTickets(Array.isArray(ticketsData) ? ticketsData : []);

      console.log('Fetching owned products...');
      const productsData = await fetchOwnedProducts(); // Use new function
      setProducts(Array.isArray(productsData.ownedProducts) ? productsData.ownedProducts : []);

      console.log('User, tickets, and products data fetched successfully.');
    } catch (error) {
      console.error('Error loading user, tickets, or products data:', error);
      setError('Failed to load user, tickets, or products data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserAndTickets();
  }, []);

  // Enable 2FA for the user
  const handleEnable2FA = async () => {
    try {
      console.log('Setting up 2FA...');
      const setupData = await setup2FA();
      setTwoFactorSetup(setupData);
      setIsModalOpen(true);
      console.log('2FA setup data:', setupData);
    } catch (error) {
      console.error('Error setting up 2FA:', error);
    }
  };

  // Verify the 2FA code entered by the user
  const handleVerify2FA = async () => {
    try {
      console.log('Verifying 2FA...');
      const result = await verify2FA(twoFactorCode);
      if (result.verified) {
        setIsVerified(true);
        setIsModalOpen(false);
        console.log('2FA verification successful');
      } else {
        console.error('2FA verification failed');
      }
    } catch (error) {
      console.error('Error verifying 2FA:', error);
    }
  };

  // Delete a ticket by ticket ID
  const handleDeleteTicket = async (ticketId) => {
    if (window.confirm('Are you sure you want to delete this ticket?')) {
      try {
        console.log('Deleting ticket:', ticketId);
        await deleteTicket(ticketId);
        setTickets(tickets.filter(ticket => ticket._id !== ticketId));
        console.log('Ticket deleted successfully.');
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    }
  };

  // Redeem a key entered by the user
  const handleRedeemKey = async () => {
    setRedeeming(true);
    try {
      console.log('Redeeming key...');
      const response = await redeemKey(redeemKeyCode);
      if (response.message === 'Key redeemed successfully') {
        console.log('Key redeemed successfully:', response);
        const productsData = await fetchOwnedProducts(); // Refresh product list
        setProducts(productsData.ownedProducts || []);
        setRedeemKeyCode('');
      } else {
        setRedeemError(response.message);
      }
    } catch (error) {
      console.error('Error redeeming key:', error);
      setRedeemError('Invalid or already used key.');
    } finally {
      setRedeeming(false);
    }
  };

  // Reset the HWID for a product
  const handleResetHWID = async (productId) => {
    try {
      console.log('Resetting HWID for product:', productId);
      await resetHWID(productId);
      alert('HWID reset successfully!');
    } catch (error) {
      console.error('Error resetting HWID:', error);
      alert('Failed to reset HWID.');
    }
  };

  if (loading) return <div className="flex items-center justify-center min-h-screen text-2xl animate-pulse">Loading...</div>;
  if (error) return <div className="flex items-center justify-center min-h-screen text-2xl">{error}</div>;

  return (
    <div className="container mx-auto p-6 lg:p-10 text-white">
      {user && (
        <div>
          {/* User Info Section */}
          <div className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 p-8 rounded-lg shadow-lg mb-12 text-center">
            <h2 className="text-4xl font-extrabold mb-6 animate-fadeIn">Welcome, {user.username}</h2>
            <p className="text-lg mb-4"><strong>Role:</strong> {user.role}</p>
            <button
              onClick={handleEnable2FA}
              className={`bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-8 rounded-full shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-110 mt-6 ${
                isVerified ? 'cursor-not-allowed opacity-50' : ''
              }`}
              disabled={isVerified}
            >
              <FaLock className="mr-2 inline-block" />
              {isVerified ? '2FA Verified' : 'Enable 2FA'}
            </button>
          </div>

          {/* Modal for 2FA Setup */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="2FA Setup"
            className="modal-content bg-gray-900 text-white p-8 rounded-xl shadow-xl max-w-md mx-auto animate-slideIn"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center"
          >
            {twoFactorSetup ? (
              <>
                <h2 className="text-3xl font-bold text-blue-500 mb-6 text-center">2FA Setup</h2>
                <p className="text-lg mb-6 text-center">Scan the QR code or enter the secret in your 2FA app:</p>
                <div className="flex justify-center mb-6">
                  <img src={twoFactorSetup.qrCode} alt="QR Code" className="w-40 h-40 rounded-lg shadow-md" />
                </div>
                <p className="text-lg break-words text-center mb-4"><strong>Secret:</strong> {twoFactorSetup.secret}</p>
                <input
                  type="text"
                  className="w-full px-4 py-3 mt-4 rounded-lg bg-gray-800 text-white border border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                  value={twoFactorCode}
                  onChange={(e) => setTwoFactorCode(e.target.value)}
                  placeholder="Enter 2FA Code"
                />
                <button
                  onClick={handleVerify2FA}
                  className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 rounded-lg shadow-lg hover:bg-blue-700 transition-transform duration-300 ease-in-out transform hover:scale-105 mt-6"
                >
                  Verify 2FA
                </button>
              </>
            ) : (
              <div>Loading 2FA setup...</div>
            )}
          </Modal>

          {/* Redeme Key Section */}
          <div className="mt-12">
            <h3 className="text-4xl font-semibold text-yellow-400 mb-8 animate-fadeInSlow">Redeem Key</h3>
            <div className="mb-4">
              <input
                type="text"
                value={redeemKeyCode}
                onChange={(e) => setRedeemKeyCode(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-800 text-white border border-primary focus:outline-none focus:ring-4 focus:ring-primary"
                placeholder="Enter your key"
              />
              <button
                onClick={handleRedeemKey}
                className="w-full mt-4 py-3 bg-primary text-white rounded-lg shadow-lg hover:bg-secondary transition-transform duration-300 ease-in-out transform hover:scale-105"
                disabled={redeeming}
              >
                {redeeming ? 'Redeeming...' : <><FaKey className="mr-2 inline-block" /> Redeem Key</>}
              </button>
              {redeemError && <p className="text-red-500 mt-2">{redeemError}</p>}
            </div>
          </div>

          {/* Products Section */}
          <div className="mt-12">
            <h3 className="text-4xl font-semibold text-blue-400 mb-8 animate-fadeInSlow">Your Products</h3>
            {Array.isArray(products) && products.length > 0 ? (
              <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-2">
                {products.map(product => (
                  <div key={product._id} className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-xl shadow-lg relative transition-transform hover:scale-105 transform-gpu duration-300">
                    <h4 className="text-2xl font-semibold mb-3 flex items-center">
                      <FaCog className="mr-2 text-yellow-400" /> {product.title}
                    </h4>
                    <p className="text-lg mb-4">HWID: {product.hwid}</p>
                    <p className="text-lg mb-4"><strong>Purchased on:</strong> {new Date(product.purchasedAt).toLocaleDateString()}</p>
                    <p className="text-lg mb-4"><strong>Expires on:</strong> {new Date(product.expiresAt).toLocaleDateString()}</p>
                    <div className="flex space-x-4">
                      {product.canResetHWID && (
                        <button
                          onClick={() => handleResetHWID(product._id)}
                          className="flex items-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-transform duration-300 ease-in-out"
                        >
                          <FaSync className="mr-2" /> Reset HWID
                        </button>
                      )}
                      {product.canAccessSettings && (
                        <button
                          className="flex items-center bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-transform duration-300 ease-in-out"
                        >
                          <FaCog className="mr-2" /> Settings
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-lg text-gray-400">You have no products.</p>
            )}
          </div>

          {/* Tickets Section */}
          <div className="mt-12">
            <h3 className="text-4xl font-semibold text-blue-400 mb-8 animate-fadeInSlow">Your Tickets</h3>
            {Array.isArray(tickets) && tickets.length > 0 ? (
              <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-2">
                {tickets.map(ticket => (
                  <div key={ticket._id} className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-xl shadow-lg relative transition-transform hover:scale-105 transform-gpu duration-300">
                    <div className="absolute top-2 right-2">
                      <button
                        onClick={() => handleDeleteTicket(ticket._id)}
                        className="text-red-500 hover:text-red-700 transition duration-200"
                      >
                        <FaTrash size={20} />
                      </button>
                    </div>
                    <h4 className="text-2xl font-semibold mb-3 flex items-center">
                      <FaTicketAlt className="mr-2 text-yellow-400" /> Ticket ID: {ticket._id}
                    </h4>
                    <p className="text-lg mb-2"><strong>Priority:</strong> {ticket.priority}</p>
                    <p className="text-lg mb-2"><strong>Reason:</strong> {ticket.reason}</p>
                    <p className="text-lg mb-2"><strong>Status:</strong> {ticket.escalated ? 'Escalated' : 'Normal'}</p>
                    <a
                      href={`/ticket/${ticket._id}`}
                      className="text-blue-400 hover:underline mt-4 inline-block transition-all duration-200 ease-in-out"
                    >
                      View Ticket
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-lg text-gray-400">You have no tickets.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
