import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchUserData } from '../utils/api';
// This file has caused me so much pain..
const ProtectedRoute = ({ children, requiredRole }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = localStorage.getItem('auth_token');

        if (token) {
          const userData = await fetchUserData();
          setUser(userData);
        } else {
          throw new Error('No token found');
        }
      } catch (error) {
        console.error('Error fetching user data or invalid token:', error);
        localStorage.removeItem('auth_token');
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && !requiredRole.includes(user.role)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
