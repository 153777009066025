import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchUsers,
  updateUserRole,
  banUser,
  removeUser,
  fetchTickets,
  deleteTicket,
  escalateTicket,
  createUser,
  loginAsUser,
  fetchResellersWithProducts,
  fetchGlobalProductSettings,
  updateGlobalProductSettings,
  fetchMaintenanceStatus,
  enableMaintenance,
  disableMaintenance,
} from '../utils/api'; // Updated API functions
import Modal from 'react-modal';
import { generateRandomCode } from '../utils/helpers';
import {
  FaTrashAlt,
  FaSignInAlt,
  FaUserShield,
  FaTicketAlt,
  FaUserPlus,
  FaArrowUp,
  FaSearch,
  FaEllipsisV,
  FaChevronDown,
} from 'react-icons/fa';

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [resellers, setResellers] = useState([]);
  const [globalSettings, setGlobalSettings] = useState({});
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState(generateRandomCode());
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(null);
  const [maintenanceStatus, setMaintenanceStatus] = useState({});
  const navigate = useNavigate();

  const dropdownRef = useRef(null);
  const roleDropdownRef = useRef(null);

  const allRoutes = [
    'autolaunch',
    'clothing-bot',
    'other',
    'chat',
    'user-dashboard',
    'resell-panel',
    'contact',
  ]; 

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const usersData = await fetchUsers();
        setUsers(usersData);

        const ticketsData = await fetchTickets();
        setTickets(ticketsData);

        const resellersData = await fetchResellersWithProducts();
        setResellers(resellersData);

        const globalSettingsData = await fetchGlobalProductSettings();
        setGlobalSettings(globalSettingsData);

        const maintenanceData = await fetchMaintenanceStatus();
        setMaintenanceStatus(maintenanceData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
        (roleDropdownRef.current && !roleDropdownRef.current.contains(event.target))
      ) {
        setDropdownOpen(null);
        setRoleDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCreateAccount = async () => {
    try {
      await createUser({ username: newUsername, password: newPassword });
      setIsCreateAccountModalOpen(false);
      setNewUsername('');
      setNewPassword(generateRandomCode());
      const updatedUsers = await fetchUsers();
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await updateUserRole(userId, newRole);
      setUsers(users.map((user) => (user._id === userId ? { ...user, role: newRole } : user)));
      setRoleDropdownOpen(null); // Close the dropdown after selecting a role
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  const handleBanUser = async (userId) => {
    try {
      await banUser(userId);
      setUsers(users.filter((user) => user._id !== userId));
    } catch (error) {
      console.error('Error banning user:', error);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      await removeUser(userId);
      setUsers(users.filter((user) => user._id !== userId));
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const handleDeleteTicket = async (ticketId) => {
    try {
      await deleteTicket(ticketId);
      setTickets(tickets.filter((ticket) => ticket._id !== ticketId));
    } catch (error) {
      console.error('Error deleting ticket:', error);
    }
  };

  const handleEscalateTicket = async (ticketId) => {
    try {
      await escalateTicket(ticketId);
      setTickets(tickets.map((ticket) => (ticket._id === ticketId ? { ...ticket, escalated: true } : ticket)));
    } catch (error) {
      console.error('Error escalating ticket:', error);
    }
  };

  const handleTicketClick = (ticketId) => {
    navigate(`/ticket/${ticketId}`);
  };

  const handleLoginAsUser = async (userId) => {
    try {
      const randomCode = generateRandomCode();
      await loginAsUser(userId, randomCode);
      navigate('/user-dashboard');
    } catch (error) {
      console.error('Error logging in as user:', error);
    }
  };

  const handleMaintenanceToggle = async (route) => {
    try {
      if (maintenanceStatus[route]) {
        await disableMaintenance(route);
        setMaintenanceStatus((prev) => ({ ...prev, [route]: false }));
      } else {
        await enableMaintenance(route);
        setMaintenanceStatus((prev) => ({ ...prev, [route]: true }));
      }
    } catch (error) {
      console.error('Error toggling maintenance:', error);
    }
  };

  const handleGlobalSettingsChange = async (setting, value) => {
    try {
      const updatedSettings = { ...globalSettings, [setting]: value };
      await updateGlobalProductSettings(updatedSettings);
      setGlobalSettings(updatedSettings);
    } catch (error) {
      console.error('Error updating global settings:', error);
    }
  };

  const filteredUsers = Array.isArray(users) ? users.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  const filteredResellers = Array.isArray(resellers) ? resellers : [];

  const RoleDropdown = ({ user }) => (
    <div ref={roleDropdownRef} className="relative">
      <button
        className="flex items-center justify-between bg-gray-800 border border-primary rounded-md py-2 px-4 text-white w-full focus:outline-none"
        onClick={() => setRoleDropdownOpen(roleDropdownOpen === user._id ? null : user._id)}
      >
        {user.role} <FaChevronDown className="ml-2" />
      </button>
      {roleDropdownOpen === user._id && (
        <div
          className="absolute left-0 right-0 mt-2 bg-gray-900 border border-primary rounded-lg shadow-lg z-10 transition-all duration-300 ease-in-out transform opacity-0"
          style={{ opacity: roleDropdownOpen === user._id ? 1 : 0, transform: 'translateY(10px)' }}
        >
          {['Member', 'Support', 'Admin', 'Owner'].map((role) => (
            <button
              key={role}
              className={`block w-full text-left px-4 py-2 hover:bg-primary transition-colors ${
                user.role === role ? 'bg-primary text-background' : 'text-white'
              }`}
              onClick={() => handleRoleChange(user._id, role)}
            >
              {role}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const ActionDropdown = ({ user }) => (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={() => setDropdownOpen(dropdownOpen === user._id ? null : user._id)}
        className="bg-gray-900 text-white p-2 rounded-full focus:outline-none"
      >
        <FaEllipsisV />
      </button>
      {dropdownOpen === user._id && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-900 rounded-lg shadow-lg py-2 z-10 transition-all duration-300 ease-in-out transform opacity-0"
          style={{ opacity: dropdownOpen === user._id ? 1 : 0, transform: 'translateY(10px)' }}>
          <button
            onClick={() => handleBanUser(user._id)}
            className="block w-full text-left px-4 py-2 text-white hover:bg-red-600 hover:text-white"
          >
            <FaTrashAlt className="mr-2" /> Ban
          </button>
          <button
            onClick={() => handleRemoveUser(user._id)}
            className="block w-full text-left px-4 py-2 text-white hover:bg-gray-600 hover:text-white"
          >
            <FaTrashAlt className="mr-2" /> Remove
          </button>
          <button
            onClick={() => handleLoginAsUser(user._id)}
            className="block w-full text-left px-4 py-2 text-white hover:bg-blue-600 hover:text-white"
          >
            <FaSignInAlt className="mr-2" /> Login
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto p-8 text-white">
      {/* Admin Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-5xl font-extrabold text-primary">Admin Panel</h1>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search users..."
              className="pl-10 pr-4 py-2 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-primary border border-primary"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <button
            onClick={() => setIsCreateAccountModalOpen(true)}
            className="flex items-center bg-primary text-background py-2 px-4 rounded-lg shadow-lg hover:bg-secondary transition-transform duration-300 ease-in-out transform hover:scale-105"
          >
            <FaUserPlus className="mr-2" /> Create Account
          </button>
        </div>
      </div>

      {/* Create Account Modal */}
      <Modal
        isOpen={isCreateAccountModalOpen}
        onRequestClose={() => setIsCreateAccountModalOpen(false)}
        contentLabel="Create Account"
        className="modal-content bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-md mx-auto"
        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-3xl font-bold text-primary mb-4">Create Account</h2>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Username"
            className="w-full px-4 py-2 rounded-lg bg-gray-800 text-white border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
          <input
            type="text"
            placeholder="Password"
            className="w-full px-4 py-2 rounded-lg bg-gray-800 text-white border border-primary focus:outline-none focus:ring-2 focus:ring-primary"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button
            onClick={handleCreateAccount}
            className="w-full flex items-center justify-center bg-primary text-background py-2 rounded-lg shadow-lg hover:bg-secondary transition-transform duration-300 ease-in-out transform hover:scale-105"
          >
            <FaUserPlus className="mr-2" /> Create
          </button>
        </div>
      </Modal>

      {/* User Cards */}
      <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-3 mb-12">
        {filteredUsers.map((user) => (
          <div key={user._id} className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col justify-between relative">
            <div className="absolute top-2 right-2">
              <ActionDropdown user={user} />
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-secondary mb-4">{user.username}</h3>
              <p className="text-lg text-text mb-4 flex items-center">
                <FaUserShield className="mr-2 text-yellow-400" /> Role: {user.role}
              </p>
              <RoleDropdown user={user} />
            </div>
          </div>
        ))}
      </div>

      {/* Resellers Section */}
      <h2 className="text-4xl font-bold text-primary mb-6">Resellers & Product Access</h2>
      <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-3 mb-12">
        {filteredResellers.map((reseller) => (
          <div key={reseller._id} className="bg-gray-800 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-secondary mb-4">{reseller.username}</h3>
            <p className="text-lg text-text mb-4">
              <strong>Products:</strong> {reseller.products.map((product) => product.title).join(', ')}
            </p>
          </div>
        ))}
      </div>

      {/* Tickets Section */}
      <h2 className="text-4xl font-bold text-primary mb-6">Tickets</h2>
      <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-3">
        {Array.isArray(tickets) && tickets.length > 0 ? (
          tickets.map((ticket) => (
            <div
              key={ticket._id}
              className="bg-gray-800 p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105"
              onClick={() => handleTicketClick(ticket._id)}
            >
              <h3 className="text-2xl font-semibold text-secondary mb-4 flex items-center">
                <FaTicketAlt className="mr-2 text-yellow-400" /> {ticket.username}
              </h3>
              <p className="text-lg text-text mb-2">
                <strong>Message:</strong> {ticket.message}
              </p>
              <p className="text-lg text-text mb-2">
                <strong>Priority:</strong> {ticket.priority}
              </p>
              <p className="text-lg text-text mb-2">
                <strong>Reason:</strong> {ticket.reason}
              </p>
              <div className="flex space-x-2 mt-4 justify-between">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEscalateTicket(ticket._id);
                  }}
                  className="flex items-center bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                >
                  <FaArrowUp className="mr-1" /> Escalate
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTicket(ticket._id);
                  }}
                  className="flex items-center bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700"
                >
                  <FaTrashAlt className="mr-1" /> Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg text-gray-400">No tickets found.</p>
        )}
      </div>

      <div className="mt-12">
        <h2 className="text-4xl font-bold text-primary mb-6">Maintenance Settings</h2>
        <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-3">
          {allRoutes.map((route) => (
            <div key={route} className="bg-gray-800 p-6 rounded-lg shadow-md flex justify-between items-center">
              <p className="text-xl text-secondary">{route.replace('-', ' ').toUpperCase()}</p>
              <label className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  checked={maintenanceStatus[route] || false}
                  onChange={() => handleMaintenanceToggle(route)}
                  className="form-checkbox text-primary h-6 w-6"
                />
                <span className="text-lg text-text">
                  {maintenanceStatus[route] ? 'Enabled' : 'Disabled'}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Global Product Settings */}
      <div className="mt-12">
        <h2 className="text-4xl font-bold text-primary mb-6">Global Product Settings</h2>
        <div className="grid grid-cols-1 gap-6">
          <div className="bg-gray-800 p-6 rounded-lg shadow-md">
            <label htmlFor="hwid-reset" className="block text-lg font-semibold mb-2">Allow HWID Reset:</label>
            <select
              id="hwid-reset"
              className="w-full px-4 py-2 rounded-lg bg-gray-800 text-white border border-primary focus:outline-none"
              value={globalSettings.allowHWIDReset || 'no'}
              onChange={(e) => handleGlobalSettingsChange('allowHWIDReset', e.target.value)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md">
            <label htmlFor="settings-access" className="block text-lg font-semibold mb-2">Allow Access to Settings:</label>
            <select
              id="settings-access"
              className="w-full px-4 py-2 rounded-lg bg-gray-800 text-white border border-primary focus:outline-none"
              value={globalSettings.allowSettingsAccess || 'no'}
              onChange={(e) => handleGlobalSettingsChange('allowSettingsAccess', e.target.value)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
